/* General Layout */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .flex {
    display: flex;
  }
  
  .min-h-screen {
    min-height: 100vh;
  }
  
  /* Sidebar */
  .sidebar {
    width: 25%;
    background-color: #1e3a8a; /* Dark blue */
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .sidebar h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 12px 16px;
    margin-bottom: 10px;
    background-color: #2563eb; /* Medium blue */
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar li:hover {
    background-color: #1e40af; /* Darker blue */
  }
  
  .sidebar p {
    text-align: center;
    font-size: 14px;
    color: #d1d5db; /* Light gray */
  }
  
  /* Main Content */
  .main-content {
    width: 75%;
    padding: 24px;
  }
  
  .main-content h1 {
    font-size: 32px;
    font-weight: bold;
    color: #1e293b; /* Dark gray */
    margin-bottom: 24px;
  }
  
  .grid {
    display: grid;
    gap: 16px;
  }
  
  .grid-cols-1 {
    grid-template-columns: 1fr;
  }
  
  .sm-grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* User Card */
  .card {
    background-color: #e0f2fe; /* Light blue */
    padding: 20px;
    border: 1px solid #93c5fd; /* Blue border */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card h2 {
    font-size: 18px;
    font-weight: bold;
    color: #1e293b;
    margin-bottom: 8px;
  }
  
  .card p {
    font-size: 14px;
    color: #64748b; /* Medium gray */
  }
  
  .badge {
    background-color: #93c5fd; /* Light blue */
    color: #1e40af; /* Dark blue */
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    margin-top: 10px;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .sidebar {
      width: 30%;
    }
  
    .main-content {
      width: 70%;
    }
  }
  
  @media (max-width: 480px) {
    .flex {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      text-align: center;
    }
  
    .main-content {
      width: 100%;
    }
  
    .grid {
      grid-template-columns: 1fr;
    }
  }
  