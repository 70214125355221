/* General Styles */
.chat-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* Sidebar */
.chat-sidebar {
  width: 25%;
  background: #2c3e50;  
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
  overflow: auto;
  overflow-x:hidden;
}

.chat-sidebar h2 {
  margin-bottom: 20px;
}

.chat-sidebar ul {
  list-style: none;
  padding: 0;
}

.chat-sidebar li {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.chat-sidebar li:hover {
  background-color: #34495e;
}

.chat-sidebar .active-user {
  background-color: #1abc9c;
  font-weight: bold;
  justify-content: space-around;
  display: flex;
  align-items: center;

}
.chat-sidebar .user-none-active {
  font-weight: bold;
  justify-content: space-around;
  display: flex;
  align-items: center;

}


/* Main Chat Window */
.chat-window {
  width: 75%;
  height: 95vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 18px; 
  background: linear-gradient(135deg, #2c3e50 0%, #1abc9c 100%);
  transition: width 0.3s ease;
}

.chat-window h1 {
  margin-bottom: 20px;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background: #cbcaca;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  margin-bottom: 20px;

  /* Add custom scrollbar */
  scrollbar-width: thin; /* For Firefox compatibility */
}

/* Custom Scrollbar for WebKit Browsers */
.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #1abc9c;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.messages::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9;
}

/* Message Styling */
.message.user {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.message.botext {
  width: fit-content;
  padding: 5px;
  background-color: #2c3e50;
  border-radius: 5px;
  color: #1abc9c;
}

.message.usertext {
  width: fit-content;
  padding: 5px;
  background-color: #1abc9c;
  border-radius: 5px;
  color: white;
}

.message.bot {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

/* Input Section */
.input-container {
  display: flex;
  gap: 10px;
}

.input-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

.input-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #1abc9c;
  color: white;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #00ffcc;
}

.input-container button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .chat-sidebar {
    width: 20%;
  }

  .chat-window {
    width: 80%;
    padding: 0;
  }

  .messages::-webkit-scrollbar {
    width: 6px;
  }
}

@media (max-width: 480px) {
  .chat-container {
    flex-direction: column;
  }

  .chat-sidebar,
  .chat-window {
    width: 100%;
    padding: 0;

  }

  .input-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .input-container input {
    flex-grow: 1;
    width: 100%;
  }

  .input-container button {
    margin: 5px 0;
  }

  /* Adjust scrollbar for mobile view */
  .messages::-webkit-scrollbar {
    width: 4px;
  }
}


@media (max-width: 480px) {
  .messages {
    font-size: 0.9rem; /* Make messages more readable on smaller screens */
  }

  .input-container button {
    padding: 8px 15px;
  }
}

.messages {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.message {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Adjusting Sidebar for Smaller Screens */
@media (max-width: 600px) {
  .chat-sidebar {
    position: fixed;
    z-index: 10;
    background-color: #2c3e50;
    height: 100%;
    width: 65%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .chat-sidebar.active {
    transform: translateX(0);
  }

  .chat-container {
    position: relative;
    overflow: hidden;
  }
}

/* Sidebar Toggle Button */
.sidebar-toggle {
  display: none;
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #1abc9c;
  border: none;
  color: white;
  font-size: 1.2rem;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  direction: ltr;
}

@media (max-width: 600px) {
  .sidebar-toggle {
    display: block;
  }
}
.ContainerUsers{
  display: flex
  ;
      justify-content: space-around;
      align-items: center;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(135deg, #2c3e50 0%, #1abc9c 100%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c3e50  ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1abc9c;
}