/* Register.css */
.body {
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #2c3e50 0%, #1abc9c 100%);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .register-card {
    background: #ffffff;
    border-radius: 12px;
    padding: 30px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .register-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333333;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .register-input {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    font-size: 14px;
  }

  
  .register-input:focus {
    outline: none;
    border-color: #1abc9c;
    box-shadow: 0 0 5px rgba(0, 255, 187, 0.5);
  }
  
  .register-select {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    font-size: 14px;
    background: #ffffff;
    cursor: pointer;
  }
  
  .register-button {
    padding: 12px;
    background: #2c3e50;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .register-button:hover {
    background: #0066cc;
  }
  
  .back-to-login {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    color: #333333;
  }
  
  .back-to-login a {
    color: #1abc9c;
    text-decoration: none;
    font-weight: bold;
  }
  
  .back-to-login a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .register-card {
      padding: 20px;
    }
  
    .register-title {
      font-size: 20px;
    }
  
    .register-input, .register-select {
      font-size: 13px;
      padding: 10px;
    }
  
    .register-button {
      font-size: 15px;
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .register-card {
      padding: 15px;
    }
  
    .register-title {
      font-size: 18px;
    }
  
    .register-input, .register-select {
      font-size: 12px;
      padding: 8px;
    }
  
    .register-button {
      font-size: 14px;
      padding: 8px;
    }
  }
  