/* Container Styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #2c3e50 0%, #1abc9c 100%);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
  }
  
  /* Title Styles */
  .title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  /* Subtitle Styles */
  .subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  /* Separator Styles */
  .separator {
    margin: 0 5px;
    color: #fff;
    font-size: 1.5rem;
  }
  
  /* OTP Input Styles */
  .input {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    font-size: 1.5rem;
    text-align: center;
    border: 2px solid #fff;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    outline: none;
    transition: transform 0.2s ease, border-color 0.2s ease;
  }
  
  .input:focus {
    transform: scale(1.1);
    border-color: #ffcc00;
  }
  
  /* Button Styles */
  .button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #fff;
    color: #6a11cb;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .button:hover {
    background-color: #e6e6e6;
    transform: scale(1.05);
  }
  