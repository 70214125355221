/* Login.css */
.body {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #2c3e50 0%, #1abc9c 100%);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px; /* For smaller screens */
}

.login-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  width: 400px;
  max-width: 100%; /* Ensure it doesn't overflow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333333;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-input {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 14px;
}

.login-input:focus {
  outline: none;
  border-color: #1abc9c;
  box-shadow: 0 0 5px rgba(0, 255, 187, 0.5);
}

.login-button {
  padding: 12px;
  background: #2c3e50;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-button:hover {
  background: #0066cc;
}
.register-link {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #333333;
}

.register-link a {
  color: #1abc9c;
  text-decoration: none;
  font-weight: bold;
}

.register-link a:hover {
  text-decoration: underline;
}
/* Responsive Design */
@media (max-width: 768px) {
  .login-card {
    padding: 20px;
  }

  .login-title {
    font-size: 20px;
  }

  .login-input {
    font-size: 13px;
    padding: 10px;
  }

  .login-button {
    font-size: 15px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .login-card {
    padding: 15px;
  }

  .login-title {
    font-size: 18px;
  }

  .login-input {
    font-size: 12px;
    padding: 8px;
  }

  .login-button {
    font-size: 14px;
    padding: 8px;
  }
}
